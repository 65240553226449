<template>
  <div v-if="delivery">
    <vx-card no-shadow class="mb-4">
      <div class="action-field flex justify-end mb-1">
        <vs-dropdown vs-trigger-click class="cursor-pointer m-0">
          <div class="p-1 active-wrap rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium" >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="initUpdateMessage()">
              <span class="flex items-center">
                <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>Edit</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="deleteDelivery()">
              <span class="flex items-center">
                <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>Delete</span>
              </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <div class="">
        <div class="mb-4">
          <div v-html="delivery.message"> </div>
          <p class="text-sm" style="color:#ccc;">Date: {{delivery.created_at | date_time}}</p>
        </div>
      </div>
      <div>
        <delivery-files v-if="delivery.files && delivery.files.length" :files="delivery.files" />
      </div>
      <div >
        <comments v-if="delivery.comments && delivery.comments.length" :comments="delivery.comments" />
      </div>
    </vx-card>
    <vs-popup class="holamundo" @close="initializeAll" title="Update delivery message" :active.sync="popupUpdateMsg">
      <div class="mt-1">
        <vs-textarea label="Write delivery message" v-model="newMessage" rows="1" />
      </div>
      <vs-button class="ml-auto mt-1" @click="updateMessage()" size="small">Update</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import DeliveryFiles from "./DeliveryFiles.vue"
import Comments from "./Comments.vue"

export default {
  components:{
    DeliveryFiles,
    Comments
  },
  props: {
    delivery : { 
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      popupUpdateMsg: false,
      newMessage:"",
    }
  },
  methods: {
    initUpdateMessage(){
      this.newMessage = this.delivery.message
      this.popupUpdateMsg = true
    },
    updateMessage(){
      if(!this.newMessage){this.alertError('Field is empty'); return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({message: this.newMessage}))
      this.resquestProcessor(formData,`${this.delivery.id}/update`)
    },
    resquestProcessor(formData, action){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/order-deliveries/${action}`, formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("orderList/UPDATE_DELIVERY", response.data.data);
          this.initializeAll()
        }
     })
    },
    deleteDelivery(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'You want to delete this delivery!',
        accept: this.acceptDelete
      })
    },
    acceptDelete(){
      this.$vs.loading({color: "#444", type:'sound'})
      this.$http.delete(`/order-deliveries/${this.delivery.id}/delete`)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("orderList/DELETE_DELIVERY", this.delivery.id)
        }
      })
    },
    initializeAll(){
      this.popupUpdateMsg = false
      this.newMessage = ""
      this.$validator.reset()
    },
  },
}
</script>

<style lang="scss">
.action-field{
  margin-top: -10px;
}
.action-field:hover{
  button{
    .active-wrap{
      transition: all 0.3s ease-in-out;
      -webkit-box-shadow: 0 7px 7px 5px rgba(0,0,0,.08);
      box-shadow: 0 7px 7px 5px rgba(0,0,0,.08);
    }

  }
}

</style>
