import { render, staticRenderFns } from "./SaveReply.vue?vue&type=template&id=4966621a&scoped=true&"
import script from "./SaveReply.vue?vue&type=script&lang=js&"
export * from "./SaveReply.vue?vue&type=script&lang=js&"
import style0 from "./SaveReply.vue?vue&type=style&index=0&id=4966621a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4966621a",
  null
  
)

export default component.exports