<template>
<form class="">
    <div class="mb-4">
        <p class="">Add a text shortcut to quickly insert this reply. Shortcuts must be at least 3 characters.</p>
    </div>
    <div class="mb-4">
        <label>Shortcut</label>
        <vs-input type="text" class="w-full" 
        v-validate="{ required: true}" name="shortcut"
        placeholder="Shortcut" v-model="data.shortcut"/>
        <span class="text-danger small" v-show="errors.has('shortcut')">{{ errors.first('shortcut') }}</span>
    </div>
    <div class="mb-4">
        <label>Message</label>
        <vs-textarea class="w-full" rows="4"
        v-validate="{ required: true, max: 1000 }" name="message"
         label="Write a message"
        counter="1000" v-model="data.message" />
        <span class="text-danger small" v-show="errors.has('message')">{{ errors.first('message') }}</span>
    </div>
    <div class="text-right">
        <div class="d-flex align-items-center justify-content-end">
            <vs-button class="mr-2 text-white" color="dark" @click.prevent="$emit('exit')"
                size="small" >Cancel</vs-button>
            <vs-button  color="primary" 
            @click.prevent="editMode? update() : create()"
                size="small">{{editMode? 'Update':'Submit'}}</vs-button>
        </div>
    </div>
</form>
</template>

<script>

export default {
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        },
    },
    data(){
        return{
            data:{
                shortcut:"",
                message:""
            }
        }
    },
    methods:{
        create() {
            this.$validator.validateAll().then(result => {
                if (!result){ this.alertError("Form is not valid"); return;}
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$vs.loading({color: "#444", type: "sound"})
                this.$http.post(`/saved-replies/create`, formData)
                .then((response) => {
                    this.$vs.loading.close()
                    if(response.data.success){
                        this.$store.commit('savedReply/ADD_ITEM', response.data.data)
                        this.$emit("exit")
                    }
                })
            })
        },
        update(){
            this.$validator.validateAll().then(result => {
                if (!result){ this.alertError("Form is not valid"); return;}
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$vs.loading({color: "#444", type: "sound"})
                this.$http.post(`/saved-replies/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('savedReply/UPDATE_ITEM', response.data.data)
                            this.$emit("exit")
                        }
                    })
            
            })
        },
        resetForm(){
            this.$validator.reset()
        }
    },
    mounted(){
        if(this.editMode){
             this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>