<template>
<div class="vx-row justify-center">
  <div class="vx-col w-full mt-3">
    <ul class="list my-2">
      <li class="list__item" v-for="(comment, index) in comments" :key="index">
        <div class="w-full">
          <span class="px-3 py-2 inline-flex rounded" style="background-color:#f8f8f8;">
            <div class="pt-2">
                <feather-icon icon="ArrowDownIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>
            </div>
            <div>
                <div class="mb-1" v-html="preText(comment.content)"></div>
                <div class="date-font-size">{{comment.created_at | date_time}}</div>
                <div class="text-right">
                  <a  v-if="!comment.replyer_id" class="text-sm" href="javascrip:void(0)" @click.prevent="initReplyComment(comment.id)">Reply </a>
                  <div v-if="comment.replyer_id" class="pr-4 pt-4">
                  <h6 class="font-semibold text-sm italic">Response</h6>
                  <div class="mb-1" v-html="preText(comment.reply)"></div>
                  <small class="date-font-size">{{comment.replied_at | date_time}}</small>
                  <a class="ml-2 text-sm" href="javascrip:void(0)" @click.prevent="initReplyEdit(comment)">Edit</a>
                </div>
              </div>
            </div>
          </span>
        </div>
      </li>
    </ul>
  </div>
  <vs-popup class="holamundo" @close="initializeAll" :title="onEditMode? 'Edit Reply':'Reply Comment'" :active.sync="popupCommentReply">
    <div class="mt-1">
      <vs-textarea label="Write your reply" v-model="onReply.reply" rows="1" />
    </div>
    <vs-button class="ml-auto mt-1" @click="onEditMode? updateReply() : replyComment()" size="small">Send</vs-button>
  </vs-popup>
</div>
</template>

<script>
export default {
    name: "comments",
    props:{
      comments: {
        required: true,
        type: Array
      }
    },
    data() {
      return {
        popupCommentReply: false,
        onReply: {
          id: null,
          reply: ""
        },
        onEditMode: false
      }
    },
    methods: {
      initReplyComment(itemId){
        if (!itemId) { return;}
        this.onReply.id = itemId
        this.popupCommentReply = true
      },
      initReplyEdit(item){
        this.onReply = {
          id: item.id,
          reply: item.reply
        }
        this.onEditMode = true;
        this.popupCommentReply = true
      },
    updateReply(){
      if(!this.onReply.reply){this.alertError('Field is empty'); return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({reply: this.onReply.reply}))
      this.resquestProcessor(formData,`${this.onReply.id}/update-reply`)
    },
    replyComment(){
      if(!this.onReply.reply){this.alertError('Field is empty'); return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({reply: this.onReply.reply}))
      this.resquestProcessor(formData,`${this.onReply.id}/reply`)
    },
    resquestProcessor(formData, action){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/delivery-comments/${action}`, formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("orderList/UPDATE_DELIVERY", response.data.data);
          this.initializeAll()
        }
     })
    },
    initializeAll(){
      this.popupCommentReply = false
      this.onReply = {
        id: null,
        reply: ""
      }
      this.onEditMode = false
      this.$validator.reset()
    }
  },
}
</script>