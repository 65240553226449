<template>
  <div id="order-view">
    <div v-if="!isLoading">
      <vs-alert v-if="order.status == 'draft'" color="warning" class="mb-4" title="Saved as draft!">
          <span class="text-sm">This order was saved as draft. The buyer is also aware of this notice.</span>
      </vs-alert>
      <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" :value="activeTab" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
        <!-- GENERAL -->
        <vs-tab icon-pack="feather" icon="icon-package" :label="!isSmallerScreen ? 'Overview' : ''">
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <order-overview />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" class="activeChild" icon="icon-file-text" :label="!isSmallerScreen ? 'Requirements' : ''">
          <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
            <order-requirements />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-send" :label="!isSmallerScreen ? 'Delivery Unit' : ''">
          <div class="tab-social-links md:ml-4 md:mt-0 mt-4 ml-0">
            <order-delivery />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-mail" :label="!isSmallerScreen ? 'Messages' : ''">
          <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
            <order-messages />
          </div>
        </vs-tab>
         <vs-tab icon-pack="feather" icon="icon-award" :label="!isSmallerScreen ? 'Review' : ''">
          <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
            <order-review />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
    <is-loading v-if="isLoading" />
  </div>
</template>

<script>
import OrderOverview from "@/components/order/OrderOverview.vue"
import OrderRequirements from "@/components/order/OrderRequirements.vue"
import OrderDelivery from "@/components/order/OrderDelivery.vue"
import OrderMessages from "@/components/order/OrderMessages.vue"
import OrderReview from "@/components/order/OrderReview.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  components: {
    OrderOverview,
    OrderRequirements,
    OrderDelivery,
    OrderMessages,
    OrderReview,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 0;
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  methods:{
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: '/error-404'})
        }
      })
    }
  },
  created(){
    this.fetchOrder();
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}

.con-vs-tabs{
    .con-ul-tabs{
        .vs-icon{
            font-size:1.5rem;
        }
    }
}
</style>
