<template>
  <span>
    <vs-button radius color="dark" @click.prevent="popupAddItem = !popupAddItem"
     class="inline mr-2" type="line" icon-pack="feather" icon="icon-message-square"></vs-button>
    
    <vs-popup class="holamundo" @close="popupAddItem = false"
    :title="editMode ? 'Edit reply' : enableForm ? 'Create saved reply' : 'Saved replies'"
    :active.sync="popupAddItem">
    <div class="">
        <div v-if="enableForm">
            <transition name="fade" mode="out-in">
                <save-reply-form v-if="enableForm" 
                :editItem="showItem" :editMode="editMode" @exit="initializeAll()"/>
            </transition>
        </div>
        <div v-else class="">
             <div class="flex mb-3 items-center justify-between">
                <vs-button size="small" @click="enableForm = true" color="primary" type="border">Add new</vs-button>
                <vs-button size="small" @click="popupAddItem = false" class="mr-2" color="dark" type="border">Close</vs-button>
            </div>
            <div class="mb-3">
                <vs-input class="w-full" type="search" 
                v-model="searchQuery" placeholder="Search..." />
            </div>
            <div style="min-height:10rem;">
                <div v-for="(item, tIndex) in displayReplies" 
                :key="tIndex" class="mb-3 rounded reply-item p-2 shadow border border-1">
                    <div class="vx-row items-center justify-between overflow-hidden">
                        <div @click="setMessage(item)" class="vx-col w-4/5 cursor-pointer">
                            <h5 class="mb-1 mt-0 text-truncate">{{item.shortcut}}</h5>
                            <p style="" class="mb-0 reply-item-desc text-truncate small">{{item.message}}</p>
                        </div>
                        <div class="reply-item-options text-right vx-col w-1/5 pl-0">
                            <vs-dropdown >
                                <vs-button radius color="dark" type="line" icon="expand_more"></vs-button>
                                <vs-dropdown-menu>
                                    <vs-dropdown-item @click="initEdit(item)">
                                    <vs-icon class="" icon="edit"></vs-icon>
                                    </vs-dropdown-item>
                                    <vs-dropdown-item divider @click="confirmDelete(item.id)">
                                        <vs-icon class="" color="danger" icon="delete"></vs-icon>
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                    </div>
                </div>
                <span v-if="!displayReplies.length">No result found</span>
            </div>
        </div>
    </div>
</vs-popup>
  </span>
</template>

<script>
import SaveReplyForm from '@/components/forms/SaveReplyForm.vue'

export default {
  components: { SaveReplyForm },
    data(){
        return{
            isLoading: true,
            enableForm:false,
            editMode: false,
            popupAddItem: false,
            showItem:null,
            searchQuery: "",
        }
    },
    computed:{
        replies(){
            return this.$store.state.savedReply.list
        },
        displayReplies(){
           return this.searchQuery 
            ? this.replies.filter((i) =>
                    i.shortcut.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    i.message.toLowerCase().includes(this.searchQuery.toLowerCase())
                    ).slice(0,10)
            : this.replies.slice(0,10)
        }
    },
    methods:{
        setMessage(item){
            this.$emit('message', item.message)
            this.popupAddItem = false
        },
        initEdit(item){
            this.showItem = {...item}
            this.enableForm = true
            this.editMode = true
        },
        confirmDelete(itemId) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'warning',
                title: 'Are you sure?',
                text: 'You are about to delete a reply.' ,
                acceptText: "Proceed",
                accept: () => this.acceptDelete(itemId)
            })
        },
        acceptDelete(itemId) {
            this.$vs.loading({color: "#444", type: "sound"})
            this.$http.delete(`/saved-replies/${itemId}/delete`)
            .then((response) =>{
                this.$vs.loading.close()
                if(response.data.success){
                    const ItemIndex = this.replies.findIndex((item) => item.id == itemId)
                    this.replies.splice(ItemIndex, 1);
                }
            })
        },
        initializeAll(){
            this.editMode = false
            this.enableForm = false
            this.showItem = null
        },
        fetchReplies(){
            this.isLoading = true
            this.$store.dispatch('savedReply/fetchReplies')
            .then((_) => { this.isLoading = false })
        }
    },
    mounted(){
       this.fetchReplies()
    }
}
</script>

<style scoped>
.reply-item{
    background:#fff;
    display: flex;
}
.reply-item:hover{
    background:#f9f9f9;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.reply-item-desc{
    word-break: break-all;
    font-size:12px;
    color:#666;
}
.reply-item-options{

}
</style>