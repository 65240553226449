
<template>
  <vx-card no-shadow>
    <div class="order-requirements justify-center items-center">
      <h6 class="text-center mb-0">Your buyer has filled out the requirements</h6>
      <vs-divider/>
      <div class="font-medium mb-4">
          <ul class="list" v-if="requirements && requirements.length">
            <template v-for="(requiredField, reIndex) in requirements">
              <li :key="reIndex"
                  class="list__item block mb-3">
                  <h6 class="mb-2" v-if="requiredField.type"> {{requiredField.title}}</h6>
                  <div class="" v-if="requiredField.type == 'photo_gallery'">
                    <div v-for="(photoType, ptIndex) of requiredField.answer" :key="ptIndex">
                      <img class="shadow" :src="absoluteUrl(photoType.value)" 
                        :alt="photoType.name" width="100" /> 
                      <div class="py-2">
                        <h6 class="mb-0 text-sm">{{photoType.name}}</h6>
                      </div>
                    </div>
                  </div>
                  <div class="" v-else-if="requiredField.type == 'color_gallery'">
                    <div class="vx-row color-gallery">
                      <div v-for="(colorType, clIndex) in requiredField.answer" :key="clIndex"
                        class="vx-col w-full md:w-1/4 mb-3">
                          <div class="color-type shadow bg-white">
                              <div class="vx-row m-0">
                                  <div v-for="(swatch, sIndex) in 9" :key="sIndex"
                                    :style="{'background-color': colorType.value.split(',')[sIndex]}"
                                    class="w-1/3 p-0 color-gallery-block">
                                      <span class="tooltiptext">{{colorType.value.split(',')[sIndex]}}</span>
                                  </div>
                              </div>
                              <div class="color-type-name py-2">
                                  <h6 class="mb-0 text-center">{{colorType.name}}</h6>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="" v-else-if="requiredField.type == 'pro_checkbox'" >
                    <div v-if="requiredField.answer" class="pro_checkbox">
                      {{requiredField.answer.map((i)=> i.name).join(", ")}}
                    </div>
                    <em v-else>Nothing was selected</em>
                  </div>
                  <div class="" v-else-if="requiredField.type == 'file_uploader'">
                    <order-attachments v-if="requiredField.answer" :files="requiredField.answer"/>
                  </div>
                  <div v-else class="text-sm" style="color:#aaa;">
                      {{requiredField.answer}}
                  </div>
              </li>
            </template>
          </ul>
      </div>
    </div>
    <vs-divider class="mb-0"/>
    <vs-list>
      <vs-list-header icon-pack="feather" icon="icon-image" title="Attached files">
      </vs-list-header>
    </vs-list>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 mb-4 w-full" v-for="(file, index) in files" :key="index">
        <a :href="`${absoluteUrl()}/download?file_path=${file.path}`" :download="file.name" style="color:inherit;" class="downloadable">
          <!-- <img v-if="isImageFile(file.extension)" width="100" :src="`/${file.path}`" :alt="file.name"> -->
          <div style="font-size: 8px;">
              <h6>{{file.name}}</h6>
              <span class="mr-2 text-sm"> size: {{file.size}}, ext.: {{file.extension}} </span>
          </div>
        </a>
      </div>
    </div>
  </vx-card>
</template>

<script>
import OrderAttachments from "@/components/OrderAttachments.vue"

export default {
  components:{
    OrderAttachments
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    files(){
      return this.order.order_files
    },
    requirements(){
      return this.order.cart.requirements
    },
  }
}
</script>



<style scoped>
.order-requirements .color-gallery .color-gallery-block{
  height: 30px;
}

.order-requirements .color-gallery .color-gallery-block {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.order-requirements .color-gallery .color-gallery-block .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.order-requirements .color-gallery .color-gallery-block .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; 
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.order-requirements .color-gallery .color-gallery-block:hover .tooltiptext {
  visibility: visible;
  width: 70px;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
  -moz-transform: translate(-50%);
  -o-transition: translate(-50%);
}

</style>