<template>
  <vx-card no-shadow>
    <div class="justify-center m-auto items-center">
         <!-- Bio -->
      <h6 class=""><span class="feather fa-cloud-upload"></span> Delivery Unit</h6>
      <vs-divider class="my-1"/>
      <vs-upload multiple text="Click to add file" :showUploadButton="false" ref="markdownfiles" class="ml-0"/>

      <div>
        <label>Write a message</label>
        <vs-textarea label="Write something" v-model="message" rows="3" />
      </div>
      <!-- Save & Reset Button -->
      <div class="flex flex-wrap pt-5 items-center justify-end">
        <save-reply @message="setSaveMessage($event)" />
        <vs-button class="ml-2" @click.prevent="createDelivery()">Deliver order</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
import SaveReply from '@/components/SaveReply.vue'
export default {
  components: { SaveReply },
  data() {
    return {
      message: "",
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
  },
  methods: {
    setSaveMessage(msg){
      this.message = msg
    },
    createDelivery(){
      const filesx = this.$refs.markdownfiles.filesx.filter((item) => (item.remove !== true))
      this.$validator.validateAll().then(result => {
        if (result && (filesx.length > 0)) {
          let formData = new FormData()
          filesx.forEach((item, index) => formData.append('file_'+index, item))
          formData.append('message', this.message)
          formData.append('order_id', this.order.id)
          this.$vs.loading({color: "#444", type: "sound"})
          this.$http.post(`/order-deliveries/create`,formData)
          .then(response => {
            this.$vs.loading.close()
            if(response.data.success){
              if(this.order.deliveries.length){
                this.$store.commit("orderList/ADD_DELIVERY", response.data.data)
              }else{
                this.$store.dispatch("orderList/fetchOrder",this.order.id)
              }
              this.$refs.markdownfiles.srcs = []
              this.$refs.markdownfiles.filesx = []
              this.message = ""
            }
          })
        }
      })
    }
  }
}
</script>

