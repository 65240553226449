<template>
  <div>
    <div class="mb-4">
        <delivery-unit />
    </div>
    <vx-card no-shadow>
      <vs-list>
        <vs-list-header icon-pack="feather" icon="icon-image" title="Delivered files">
        </vs-list-header>
      </vs-list>
      <vs-alert color="warning" title="Delivery Items" :active.sync="showDelivery">
        <span>No Delivery to show for this order. </span>
      </vs-alert>
      <div v-if="deliveries.length">
        <div no-shadow class="mb-4" v-for="(delivery, index) in deliveries" :key="index">
          <deliveries :delivery="delivery" />
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>

import Deliveries from "./components/Deliveries.vue"
import DeliveryUnit from "./components/DeliveryUnit.vue"

export default {
  components: {
    Deliveries,
    DeliveryUnit
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    deliveries(){
      return this.order.deliveries
    },
    showDelivery(){
        return this.deliveries.length < 1
    }
  },
}
</script>
