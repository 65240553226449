<template>
  <div>
    <div>
      <vx-card no-shadow>
        <VuePerfectScrollbar class="message-content-scroll-area border border-solid d-theme-border-grey-light" :settings="settings" ref="messageLogPS" :key="$vs.rtl">
          <div class="message-wrapper" ref="messageLog">
            <ul class="list my-2">
              <li class="list__item" v-for="(message, index) in messages" :key="index">
                <div v-if="isSentUser(message.who)" style="padding-left:10%;"  class="w-full">
                  <span class="p-3 inline-flex float-right rounded" style="background-color:#f8f8f8;">
                    <div>
                      <div class="text-right" v-html="preText(message.content)"></div>
                      <div class="pt-1 text-right date-font-size">{{message.created_at | date_time}}</div>
                      <file-display v-if="message.files && message.files.length" :list="message.files" />
                      <div class="text-right">
                        <a class="ml-2 text-sm" href="javascrip:void(0)" @click.prevent="initEditMessage(message)">Edit</a>
                        <a class="ml-2 text-sm text-danger" href="javascrip:void(0)" @click.prevent="confirmDelete(message.id)">Delete</a>
                        <div v-if="message.replyer_id" class="pr-4 pt-1">
                          <vs-divider/>
                          <h6 class="font-semibold text-sm italic">Client Replied</h6>
                          <div class="mb-1" v-html="preText(message.reply)"> </div>
                          <small class="date-font-size">{{message.replied_at | date_time}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="pt-2">
                      <feather-icon icon="ArrowUpIcon" class="w-5 h-5 text-warning ml-1"></feather-icon>
                    </div>
                  </span>
                </div>
                <div v-else class="w-full" style="padding-right:10%;">
                  <span class="px-3 py-2 inline-flex rounded" style="background-color:#f8f8f8;">
                    <div class="pt-2">
                      <feather-icon icon="ArrowDownIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>
                    </div>
                    <div>
                      <div v-html="preText(message.content)"></div>
                      <div class="pt-1 date-font-size">{{message.created_at | date_time}}</div>
                      <file-display v-if="message.files && message.files.length" :list="message.files" />
                      <div class="text-sm">
                        <a v-if="!message.replyer_id" class="mr-2 pt-2 inline-block text-success" href="javascrip:void(0)" @click.prevent="initReply(message.id)">Reply</a>
                        <a class="mr-2 font-bold text-danger" href="javascrip:void(0)" @click.prevent="confirmDelete(message.id)">Delete</a>
                        <div v-if="message.replyer_id" class="pl-4 pt-1">
                          <vs-divider/>
                          <h6 class="font-semibold text-sm italic">You Replied</h6>
                          <div v-html="preText(message.reply)" class="mb-1">  </div>
                          <small class="date-font-size">{{message.replied_at | date_time}}</small>
                        </div>
                      </div>
                    </div>
                 </span>
                </div>
              </li>
            </ul>
          </div>
        </VuePerfectScrollbar>
        <div class="mt-5 mb-3">
          <h5 class="mb-3">Have something to share?</h5>
          <vs-textarea class="w-full" rows="4" name="content" 
            counter="1000" label="Type your message here" v-model="newMessage" />
          </div>
        <div class="flex flex-wrap items-center justify-end">
            <vs-button radius color="dark" @click.prevent="popupAttachment = true"  class="inline mr-2" type="line" icon-pack="feather" icon="icon-paperclip"></vs-button>
            <save-reply @message="setSaveMessage($event)" />
            <vs-button type="filled" @click.prevent="createMessage" class="mt-2 block">Send</vs-button>
        </div>
      </vx-card>

      <vs-popup class="holamundo" @close="initializeAll" title="Reply Message" :active.sync="popupReplyMessage">
        <div class="mt-1">
          <vs-textarea label="Write your comment" v-model="onReply.reply" rows="1" />
        </div>
          <vs-button class="ml-auto mt-2" @click="sendReply()" size="small">Send Reply</vs-button>
      </vs-popup>

      <vs-popup class="holamundo" @close="initializeAll" title="Update Message" :active.sync="popupEditMessage">
        <div class="mt-1">
          <vs-textarea label="Write your comment" v-model="onEdit.content" rows="1" />
        </div>
          <vs-button class="ml-auto mt-2" @click="updateMessage()" size="small">Update</vs-button>
      </vs-popup>

      <vs-popup
        @close="initializeAll"
        title="Add Files"
        :active.sync="popupAttachment">
         <upload-attachment @pushAddedFiles="sendAttachment($event)" ref="fileUploaderView" />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import UploadAttachment from './components/UploadAttachment.vue'
import SaveReply from '@/components/SaveReply.vue'

export default {
  components: {
    VuePerfectScrollbar,
    UploadAttachment,
    SaveReply
  },
  data(){
    return {
      newMessage: "",
      popupReplyMessage:false,
      onReply: {
        id: null,
        reply: "",
      },
      settings: {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.70,
      },
      popupEditMessage:false,
      onEdit: {},
      popupAttachment: false,
    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    activeUser() {
      return this.$store.state.AppActiveUser
    },
    order(){
      return this.$store.state.orderList.order
    },
    messages(){
      return this.order.messages ? this.order.messages : []
    }
  },
  methods: {
    isSentUser(who){
      return  who == 'admin'
    },
    setSaveMessage(msg){
      this.newMessage = msg
    },
    sendAttachment(files){
      if(files.length < 1){this.alertError('No file'); return}
      let formData = new FormData()
      formData.append('order_id', this.order.id)
      files.forEach((item, index) => formData.append('file_'+index, item))
      this.resquestProcessor(formData, "attachments")
    },
    updateMessage(){
      if(!this.onEdit.content){this.alertError('Field is empty'); return}
      const formData = new FormData()
      formData.append('data', JSON.stringify({"content": this.onEdit.content}))
      this.resquestProcessor(formData,`${this.onEdit.id}/update`, 'UPDATE')
    },
    createMessage(){
      if(!this.newMessage){this.alertError('Field is empty'); return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({
        "content": this.newMessage,
        "order_id": this.order.id
      }))
      this.resquestProcessor(formData,'create')
    },
    sendReply(){
      if(!this.onReply.reply){this.alertError('Field is empty'); return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({reply: this.onReply.reply }))
      this.resquestProcessor(formData,`${this.onReply.id}/reply`, 'UPDATE')
    },
    resquestProcessor(formData, action, ftn='ADD'){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/order-messages/${action}`,formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.initializeAll()
          this.$store.commit(`orderList/${ftn}_MESSAGE`, response.data.data)
          if(ftn == 'ADD'){this.scrollMessageAreaUp();}
        }
     })
    },
    initEditMessage(message){
      this.onEdit = {
        id: message.id,
        content: message.content,
      }
      this.popupEditMessage = true
    },
    initReply(itemId){
      this.onReply.id = itemId
      this.popupReplyMessage = true
    },
    confirmDelete(itemId) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'You are about to delete a messsage.' ,
        acceptText: "Proceed",
        accept: () => this.acceptDelete(itemId)
      })
    },
    acceptDelete(itemId) {
      this.$vs.loading({color: "#444", type: "sound"});
      this.$http.delete(`/order-messages/${itemId}/delete`)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("orderList/DELETE_MESSAGE", itemId)
        }
      })
    },
    scrollMessageAreaUp(){
      setTimeout(() => {
        this.$refs.messageLogPS.$el.scrollTop = this.$refs.messageLog.scrollHeight
      },1000)
    },
    initializeAll(){
      this.popupReplyMessage = false
      this.onReply = {
        id: null,
        reply: "",
      }
      this.popupEditMessage = false
      this.onEdit = {}
      this.newMessage = ""
      this.popupAttachment =  false
      this.$validator.reset()
    },
  },
  mounted(){
    this.scrollMessageAreaUp();
  }
}
</script>

<style lang="scss" scoped>
  .message-content-scroll-area {
      position: relative;
      margin: auto;
      width: 100%;
      height:70vh;
  }
</style>
